
.leisure-time{

}
.leisure-time-list{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 20px;
  margin-bottom: 60px;

  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
  }


  @media (max-width: 540px) {
    grid-template-columns: 1fr;
  }
}
.leisure-time-item{
  text-decoration: none;
  transition: .3s all;
  &:hover{
    .leisure-time-item__title{
      color: #E5153E;
    }
  }


  &__img{
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  &__title{
    margin-top: 10px;
    color:#212121;
    font-size: 19px;
    margin-bottom: 10px;

  }
  &__desc{
    color:#939393;
  }
}