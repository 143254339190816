.about {
  margin-top: 60px;
  background-color: white;

  @media (max-width: 900px) {
    margin-top: 30px;

  }

  .about__wrapper {
    //min-height: 600px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    align-items: center;

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      grid-gap: 0;

    }

    .about__right {
      overflow: hidden;
      padding-bottom: 50px;

      .about__background {
        width: 100%;

      }

      // carousel
      .about__image {
        background-size: cover;
        background-position: center;
        width: 100%;
        min-height: 450px;

        height: 100%;
        overflow: hidden;

        @media (max-width: 1024px) {
          height: 400px;
        }

        @media (max-width: 768px) {
          height: 250px;
        }
      }

      // slick dots
      .slick-dots {
        bottom: -50px;
      }
    }
  }
}