
.title-with-description{
  padding: 0 25px;

  &.center {
    margin: 0 auto;
    text-align: center;

    & *{
      margin: 0 auto;
      text-align: center;
    }
  }

  p,div, ul{
    margin-bottom: 15px;
  }

  h1{
    color:#212121;
    font-weight: bold;
    font-size: 48px;
    line-height: 59px;
    margin-bottom: 20px;
    font-family: "Roboto", sans-serif;
    max-width: 700px;

    @media (max-width: 900px) {
      font-size: 34px;
      line-height: 39px;
      font-weight: 400;
    }

  }

  .title-with-description__description{
    max-width: 1000px;
    color:#444444;
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 30px;

    ul{
      margin-left: 20px;
    }

    li{
      list-style: disc;
    }

    *{
      font-size: 18px;
    }
  }
}