
.drop-down {
  position: relative;
  width: auto;
  margin-left: 30px;

  .drop-down__active {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: white;
    font-size: 16px;
    font-weight: 400;
    transition: .3s all;

    &:hover {
      //color:#B18B00;

      path {
        fill: #E5153E;
      }


    }

    path {
      fill: white;
      transition: .3s all;
    }

    svg {
      margin-left: 5px;
    }

  }

  .drop-down__list {
    padding: 5px;
    z-index: 20;
    position: absolute;
    top: 30px;
    left: -12px;
    background-color: white;
    box-shadow: 4px -4px 38px -1px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;

    &:after {
      position: absolute;
      z-index: 10;
      display: block;
      content: "";
      border: 8px solid transparent;
      border-bottom: 8px solid white;
      top: -14px;
      left: calc(50% - 8px);
    }

    &.hide {
      display: none !important;
    }

    .drop-down__item {
      display: flex;
      padding: 5px 10px;
      cursor: pointer;
      color: #444444;
      font-size: 14px;
      font-weight: 400;
      transition: .3s all;

      &:last-child {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      &:first-child {
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
      }

      &:hover {
        color:#E5153E;
        //color: #444444;
      }

      .drop-down__icon {
        width: 20px;
        display: flex;
        justify-content: center;
      }
      .drop-down__text {

      }
    }
  }
}

.button__vote {
  background-color: white;
  max-width: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #3b5998;
  padding: 10px 30px 10px 20px;
}

.button__text {
  margin-left: 10px;
  color: #3b5998;
  text-align: center;
}