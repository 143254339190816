.mobile__lng{
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  li{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    min-width: 40px;
    font-size: 14px;
    font-weight: 600;
      color: white;

    &.active{
      background-color: #E5153E;
    }
  }
}