

// mobile menu burger //
.menu__mobile-burger {
  display: none;
  width: 30px;
  height: 20px;
  position: relative;
  cursor: pointer;

  @media (max-width: 1124px) { //1124 //
    display: block;
  }

  &.boogrer-open {
    background-color: rgba(0,0,0,0);
    z-index: 99899;
    height: 40px;
    width: 40px;
    border-radius: 50%;

    .menu___hr-top {
      width: 16px;
      top: 13px;
      left: 15.5px;
      transform-origin: left top;
      transform: rotate(45deg);
      background-color: white;

    }
    .menu___hr-center {
      top: calc(50% - 1.5px);
      left: 0;
      width: 16px;
      transform: translate(50px);
      opacity: 0;

      background-color: white;
    }
    .menu___hr-bottom {

      width: 16px;
      bottom: 14px;
      left: 15.5px;
      transform: rotate(-45deg);
      transform-origin: bottom left;
      background-color: white;
    }
  }

  .menu___hr-top, .menu___hr-center, .menu___hr-bottom {
    width: 30px;
    height: 3px;
    background-color: white;
    position: absolute;
    transition: .3s all;
  }
  .menu___hr-top {
    top: 0;
    left: 0;
  }
  .menu___hr-center {
    top: calc(50% - 1.5px);
    left: 0;
    opacity: 1;
  }
  .menu___hr-bottom {
    bottom: 0;
    left: 0;
  }

}