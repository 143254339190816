.header{
  margin-top: 80px;
  //padding-top: 30px;
  height: 63vh;
  background-color: #3E4447;

  @media (max-width: 768px) {
    height: 300px;
  }

  .header__carousel{



  .header__background{
    width: 100%;
    height: 70vh;
    background-size: cover;
    background-position: center;

    @media (max-width: 768px) {
      height: 300px;
    }
   }
  }



}